























import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";

@Component
export default class Distances extends Vue {
  public distances: any = [];

  private async getDistances() {
    this.distances = await adminService.getDistances();
  }

  public async mounted() {
    await this.getDistances();
  }
}
